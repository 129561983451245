<script setup lang="ts">
const {
  status,
  data: videos,
  execute,
} = await useCachedFetch("/videos", { method: "post", immediate: false })
const filteredVideos = computed(() => {
  if (searchTerm.value.length == 0) {
    return []
  }
  return Object.values(videos.value ?? {})
    .flat()
    .filter((v) =>
      v.title
        .toLocaleLowerCase()
        .includes(searchTerm.value.toLocaleLowerCase()),
    )
})

const open = defineModel<boolean>("open")
const searchTerm = ref("")

const focusedItem = ref<HTMLAnchorElement>()
const linkContainer = ref<HTMLDivElement>()

const input = ref<HTMLInputElement>()
watchEffect(() => {
  if (open.value && input.value) {
    input.value.focus()
  }
})

watch(open, async () => {
  // Fetch when opened first time. Component is always mounted and we don't want to fetch on page load
  if (status.value != "success") {
    execute()
  }
  if (!open.value) {
    searchTerm.value = ""
  }
})
function onNextElement() {
  if (!linkContainer.value || !linkContainer.value.children[0]) {
    return
  }
  if (!focusedItem.value) {
    focusedItem.value = linkContainer.value.children[0] as HTMLAnchorElement
    focusedItem.value?.focus()
    return
  }
  const next = focusedItem.value?.nextElementSibling as
    | HTMLAnchorElement
    | undefined
  if (next) {
    next.focus()
    focusedItem.value = next
  }
}
function onPreviousElement() {
  const previous = focusedItem.value?.previousElementSibling as
    | HTMLAnchorElement
    | undefined
  if (previous) {
    previous.focus()
    focusedItem.value = previous
  } else {
    // Focus input field when reaching top of list
    input.value?.focus()
  }
}
const { trackEvent } = useAnalytics()
function trackSearchResult(id: string) {
  trackEvent("search_result_clicked", { lesson_id: id, lesson_type: "video" })
}
</script>
<template>
  <DialogWrapper v-model:open="open">
    <Transition
      enter-active-class="duration-200 ease-out"
      enter-from-class="transform opacity-0 translate-y-40"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="duration-200 ease-in"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="transform opacity-0 translate-y-40"
      appear
      ><dialog
        v-if="open"
        :open
        class="fixed inset-x-0 top-20 z-50 mx-auto w-full max-w-2xl overflow-hidden rounded-xl bg-slate-900 text-white lg:w-1/2"
        @keydown.up.prevent="onPreviousElement"
        @keydown.down.prevent="onNextElement"
      >
        <div class="relative border-b border-slate-800">
          <div
            class="pointer-events-none absolute left-0 flex h-12 items-center pl-3"
          >
            <IconSearch class="absolute size-5 flex-none text-slate-400" />
          </div>
          <input
            ref="input"
            v-model="searchTerm"
            data-testid="input_search"
            type="text"
            :placeholder="$t('search.placeholder')"
            class="h-12 w-full border-0 bg-transparent pl-10 pr-4 text-white placeholder-slate-500 focus:ring-0 sm:text-sm"
          />
        </div>
        <div
          v-if="!searchTerm"
          class="border-b border-slate-800 px-4 pb-4 pt-2"
        >
          <span class="text-sm font-bold">{{ $t("search.popular") }}</span>
          <ul class="mt-2 flex flex-col gap-1 text-sm">
            <li v-for="term in $tm('search.popular_terms')" :key="term">
              <button
                class="text-gray-400 transition hover:text-gray-200"
                @click="searchTerm = term"
              >
                {{ term }}
              </button>
            </li>
          </ul>
        </div>
        <div
          class="overflow-y-scroll py-2"
          :class="{
            'h-64': !filteredVideos?.length,
            'max-h-96': filteredVideos?.length,
          }"
          :style="{ scrollbarColor: 'rgb(60 96 120) rgb(15 23 42)' }"
        >
          <div
            v-if="searchTerm && status != 'success'"
            class="flex items-center justify-center pt-4"
          >
            <AnimationsLoadingSpinner dark />
          </div>
          <div
            v-else-if="filteredVideos?.length"
            ref="linkContainer"
            class="flex flex-col"
          >
            <NuxtLink
              v-for="(video, index) in filteredVideos"
              :key="video.id"
              :tabindex="0"
              class="flex items-center gap-6 px-4 py-2 outline-none hover:bg-slate-800 focus:bg-slate-800"
              :to="`/videos/${camelToKebab(video.category ?? '')}/video/${video.id}`"
              :data-testid="`searchresult_${index}`"
              @click.prevent="
                () => {
                  trackSearchResult(video.id)

                  open = false
                }
              "
              @focus="
                (e: FocusEvent) => (focusedItem = e.target as HTMLAnchorElement)
              "
              @blur="focusedItem = undefined"
              @keydown.enter="
                () => {
                  trackSearchResult(video.id)
                  navigateTo(
                    `/videos/${camelToKebab(video.category ?? '')}/video/${video.id}`,
                  )
                }
              "
            >
              <img
                :src="video.image"
                class="aspect-video h-min w-1/6 rounded"
                :alt="video.title"
              />
              <div class="flex flex-col overflow-hidden truncate">
                <TypographyHeading class="!text-slate-200" :level="4">{{
                  video.title
                }}</TypographyHeading>
                <span
                  class="gap-4 truncate text-sm font-light text-slate-200"
                  >{{ video.description }}</span
                >
              </div>
            </NuxtLink>
          </div>
          <div
            v-else-if="searchTerm?.length"
            class="flex h-full w-full flex-col items-center justify-center gap-2"
          >
            <IconError class="size-8 text-slate-500" />
            <span class="text-slate-300">
              {{ $t("search.no_results") }}
            </span>
          </div>
          <div
            v-else
            class="flex h-full w-full flex-col items-center justify-center gap-2"
          >
            <IconDocument class="size-8 text-slate-500" />
            <span class="text-slate-300">
              {{ $t("search.empty") }}
            </span>
          </div>
        </div>
      </dialog></Transition
    >
  </DialogWrapper>
</template>
